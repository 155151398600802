import 'firebase/auth';
import 'firebase/functions';
import 'firebase/analytics';
import './src/static/fonts/fonts.css';

export const onClientEntry = async () => {
  // IntersectionObserver polyfill for unsupported browser
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
    console.log('# IntersectionObserver is polyfilled!');
  }
  return;
};
