// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-accessories-bedsheets-egyptian-cotton-bedding-set-tsx": () => import("./../../../src/pages/accessories/bedsheets/egyptian-cotton-bedding-set.tsx" /* webpackChunkName: "component---src-pages-accessories-bedsheets-egyptian-cotton-bedding-set-tsx" */),
  "component---src-pages-accessories-bedsheets-egyptian-cotton-duvet-cover-tsx": () => import("./../../../src/pages/accessories/bedsheets/egyptian-cotton-duvet-cover.tsx" /* webpackChunkName: "component---src-pages-accessories-bedsheets-egyptian-cotton-duvet-cover-tsx" */),
  "component---src-pages-accessories-bedsheets-egyptian-cotton-fitted-sheet-tsx": () => import("./../../../src/pages/accessories/bedsheets/egyptian-cotton-fitted-sheet.tsx" /* webpackChunkName: "component---src-pages-accessories-bedsheets-egyptian-cotton-fitted-sheet-tsx" */),
  "component---src-pages-accessories-bedsheets-egyptian-cotton-pillowcase-set-tsx": () => import("./../../../src/pages/accessories/bedsheets/egyptian-cotton-pillowcase-set.tsx" /* webpackChunkName: "component---src-pages-accessories-bedsheets-egyptian-cotton-pillowcase-set-tsx" */),
  "component---src-pages-accessories-seres-silk-duvet-tsx": () => import("./../../../src/pages/accessories/seres-silk-duvet.tsx" /* webpackChunkName: "component---src-pages-accessories-seres-silk-duvet-tsx" */),
  "component---src-pages-accessories-waterproof-mattress-protector-tsx": () => import("./../../../src/pages/accessories/waterproof-mattress-protector.tsx" /* webpackChunkName: "component---src-pages-accessories-waterproof-mattress-protector-tsx" */),
  "component---src-pages-account-dashboard-tsx": () => import("./../../../src/pages/account/dashboard.tsx" /* webpackChunkName: "component---src-pages-account-dashboard-tsx" */),
  "component---src-pages-account-forget-password-tsx": () => import("./../../../src/pages/account/forget-password.tsx" /* webpackChunkName: "component---src-pages-account-forget-password-tsx" */),
  "component---src-pages-account-login-tsx": () => import("./../../../src/pages/account/login.tsx" /* webpackChunkName: "component---src-pages-account-login-tsx" */),
  "component---src-pages-account-profile-general-tsx": () => import("./../../../src/pages/account/profile/general.tsx" /* webpackChunkName: "component---src-pages-account-profile-general-tsx" */),
  "component---src-pages-account-profile-settings-tsx": () => import("./../../../src/pages/account/profile/settings.tsx" /* webpackChunkName: "component---src-pages-account-profile-settings-tsx" */),
  "component---src-pages-account-register-tsx": () => import("./../../../src/pages/account/register.tsx" /* webpackChunkName: "component---src-pages-account-register-tsx" */),
  "component---src-pages-adjustable-tsx": () => import("./../../../src/pages/adjustable.tsx" /* webpackChunkName: "component---src-pages-adjustable-tsx" */),
  "component---src-pages-bedsheets-index-tsx": () => import("./../../../src/pages/bedsheets/index.tsx" /* webpackChunkName: "component---src-pages-bedsheets-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog/[...].tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-grabpay-tsx": () => import("./../../../src/pages/grabpay.tsx" /* webpackChunkName: "component---src-pages-grabpay-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mattresses-index-tsx": () => import("./../../../src/pages/mattresses/index.tsx" /* webpackChunkName: "component---src-pages-mattresses-index-tsx" */),
  "component---src-pages-mattresses-mysa-tsx": () => import("./../../../src/pages/mattresses/mysa.tsx" /* webpackChunkName: "component---src-pages-mattresses-mysa-tsx" */),
  "component---src-pages-mattresses-original-tsx": () => import("./../../../src/pages/mattresses/original.tsx" /* webpackChunkName: "component---src-pages-mattresses-original-tsx" */),
  "component---src-pages-mattresses-terra-tsx": () => import("./../../../src/pages/mattresses/terra.tsx" /* webpackChunkName: "component---src-pages-mattresses-terra-tsx" */),
  "component---src-pages-original-woosa-pillow-tsx": () => import("./../../../src/pages/original-woosa-pillow.tsx" /* webpackChunkName: "component---src-pages-original-woosa-pillow-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-post-preview-tsx": () => import("./../../../src/pages/post/preview.tsx" /* webpackChunkName: "component---src-pages-post-preview-tsx" */),
  "component---src-pages-reviews-adjustable-tsx": () => import("./../../../src/pages/reviews/adjustable.tsx" /* webpackChunkName: "component---src-pages-reviews-adjustable-tsx" */),
  "component---src-pages-reviews-egyptian-cotton-bedding-set-tsx": () => import("./../../../src/pages/reviews/egyptian-cotton-bedding-set.tsx" /* webpackChunkName: "component---src-pages-reviews-egyptian-cotton-bedding-set-tsx" */),
  "component---src-pages-reviews-egyptian-cotton-duvet-cover-tsx": () => import("./../../../src/pages/reviews/egyptian-cotton-duvet-cover.tsx" /* webpackChunkName: "component---src-pages-reviews-egyptian-cotton-duvet-cover-tsx" */),
  "component---src-pages-reviews-egyptian-cotton-fitted-sheet-tsx": () => import("./../../../src/pages/reviews/egyptian-cotton-fitted-sheet.tsx" /* webpackChunkName: "component---src-pages-reviews-egyptian-cotton-fitted-sheet-tsx" */),
  "component---src-pages-reviews-egyptian-cotton-pillowcase-set-tsx": () => import("./../../../src/pages/reviews/egyptian-cotton-pillowcase-set.tsx" /* webpackChunkName: "component---src-pages-reviews-egyptian-cotton-pillowcase-set-tsx" */),
  "component---src-pages-reviews-seres-silk-duvet-tsx": () => import("./../../../src/pages/reviews/seres-silk-duvet.tsx" /* webpackChunkName: "component---src-pages-reviews-seres-silk-duvet-tsx" */),
  "component---src-pages-reviews-the-mysa-mattress-tsx": () => import("./../../../src/pages/reviews/the-mysa-mattress.tsx" /* webpackChunkName: "component---src-pages-reviews-the-mysa-mattress-tsx" */),
  "component---src-pages-reviews-the-original-mattress-tsx": () => import("./../../../src/pages/reviews/the-original-mattress.tsx" /* webpackChunkName: "component---src-pages-reviews-the-original-mattress-tsx" */),
  "component---src-pages-reviews-the-terra-mattress-tsx": () => import("./../../../src/pages/reviews/the-terra-mattress.tsx" /* webpackChunkName: "component---src-pages-reviews-the-terra-mattress-tsx" */),
  "component---src-pages-reviews-the-woosa-pillow-tsx": () => import("./../../../src/pages/reviews/the-woosa-pillow.tsx" /* webpackChunkName: "component---src-pages-reviews-the-woosa-pillow-tsx" */),
  "component---src-pages-reviews-the-woosa-storage-base-tsx": () => import("./../../../src/pages/reviews/the-woosa-storage-base.tsx" /* webpackChunkName: "component---src-pages-reviews-the-woosa-storage-base-tsx" */),
  "component---src-pages-select-delivery-tsx": () => import("./../../../src/pages/select-delivery.tsx" /* webpackChunkName: "component---src-pages-select-delivery-tsx" */),
  "component---src-pages-sg-about-tsx": () => import("./../../../src/pages/sg/about.tsx" /* webpackChunkName: "component---src-pages-sg-about-tsx" */),
  "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-bedding-set-tsx": () => import("./../../../src/pages/sg/accessories/bedsheets/egyptian-cotton-bedding-set.tsx" /* webpackChunkName: "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-bedding-set-tsx" */),
  "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-duvet-cover-tsx": () => import("./../../../src/pages/sg/accessories/bedsheets/egyptian-cotton-duvet-cover.tsx" /* webpackChunkName: "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-duvet-cover-tsx" */),
  "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-fitted-sheet-tsx": () => import("./../../../src/pages/sg/accessories/bedsheets/egyptian-cotton-fitted-sheet.tsx" /* webpackChunkName: "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-fitted-sheet-tsx" */),
  "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-pillowcase-set-tsx": () => import("./../../../src/pages/sg/accessories/bedsheets/egyptian-cotton-pillowcase-set.tsx" /* webpackChunkName: "component---src-pages-sg-accessories-bedsheets-egyptian-cotton-pillowcase-set-tsx" */),
  "component---src-pages-sg-accessories-seres-silk-duvet-tsx": () => import("./../../../src/pages/sg/accessories/seres-silk-duvet.tsx" /* webpackChunkName: "component---src-pages-sg-accessories-seres-silk-duvet-tsx" */),
  "component---src-pages-sg-accessories-waterproof-mattress-protector-tsx": () => import("./../../../src/pages/sg/accessories/waterproof-mattress-protector.tsx" /* webpackChunkName: "component---src-pages-sg-accessories-waterproof-mattress-protector-tsx" */),
  "component---src-pages-sg-adjustable-tsx": () => import("./../../../src/pages/sg/adjustable.tsx" /* webpackChunkName: "component---src-pages-sg-adjustable-tsx" */),
  "component---src-pages-sg-bedsheets-index-tsx": () => import("./../../../src/pages/sg/bedsheets/index.tsx" /* webpackChunkName: "component---src-pages-sg-bedsheets-index-tsx" */),
  "component---src-pages-sg-blog-tsx": () => import("./../../../src/pages/sg/blog/[...].tsx" /* webpackChunkName: "component---src-pages-sg-blog-tsx" */),
  "component---src-pages-sg-checkout-tsx": () => import("./../../../src/pages/sg/checkout.tsx" /* webpackChunkName: "component---src-pages-sg-checkout-tsx" */),
  "component---src-pages-sg-faq-tsx": () => import("./../../../src/pages/sg/faq.tsx" /* webpackChunkName: "component---src-pages-sg-faq-tsx" */),
  "component---src-pages-sg-grabpay-tsx": () => import("./../../../src/pages/sg/grabpay.tsx" /* webpackChunkName: "component---src-pages-sg-grabpay-tsx" */),
  "component---src-pages-sg-index-tsx": () => import("./../../../src/pages/sg/index.tsx" /* webpackChunkName: "component---src-pages-sg-index-tsx" */),
  "component---src-pages-sg-mattresses-index-tsx": () => import("./../../../src/pages/sg/mattresses/index.tsx" /* webpackChunkName: "component---src-pages-sg-mattresses-index-tsx" */),
  "component---src-pages-sg-mattresses-mysa-tsx": () => import("./../../../src/pages/sg/mattresses/mysa.tsx" /* webpackChunkName: "component---src-pages-sg-mattresses-mysa-tsx" */),
  "component---src-pages-sg-mattresses-original-tsx": () => import("./../../../src/pages/sg/mattresses/original.tsx" /* webpackChunkName: "component---src-pages-sg-mattresses-original-tsx" */),
  "component---src-pages-sg-mattresses-terra-tsx": () => import("./../../../src/pages/sg/mattresses/terra.tsx" /* webpackChunkName: "component---src-pages-sg-mattresses-terra-tsx" */),
  "component---src-pages-sg-original-woosa-pillow-tsx": () => import("./../../../src/pages/sg/original-woosa-pillow.tsx" /* webpackChunkName: "component---src-pages-sg-original-woosa-pillow-tsx" */),
  "component---src-pages-sg-payment-tsx": () => import("./../../../src/pages/sg/payment.tsx" /* webpackChunkName: "component---src-pages-sg-payment-tsx" */),
  "component---src-pages-sg-reviews-adjustable-tsx": () => import("./../../../src/pages/sg/reviews/adjustable.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-adjustable-tsx" */),
  "component---src-pages-sg-reviews-egyptian-cotton-bedding-set-tsx": () => import("./../../../src/pages/sg/reviews/egyptian-cotton-bedding-set.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-egyptian-cotton-bedding-set-tsx" */),
  "component---src-pages-sg-reviews-egyptian-cotton-duvet-cover-tsx": () => import("./../../../src/pages/sg/reviews/egyptian-cotton-duvet-cover.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-egyptian-cotton-duvet-cover-tsx" */),
  "component---src-pages-sg-reviews-egyptian-cotton-fitted-sheet-tsx": () => import("./../../../src/pages/sg/reviews/egyptian-cotton-fitted-sheet.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-egyptian-cotton-fitted-sheet-tsx" */),
  "component---src-pages-sg-reviews-egyptian-cotton-pillowcase-set-tsx": () => import("./../../../src/pages/sg/reviews/egyptian-cotton-pillowcase-set.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-egyptian-cotton-pillowcase-set-tsx" */),
  "component---src-pages-sg-reviews-seres-silk-duvet-tsx": () => import("./../../../src/pages/sg/reviews/seres-silk-duvet.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-seres-silk-duvet-tsx" */),
  "component---src-pages-sg-reviews-the-mysa-mattress-tsx": () => import("./../../../src/pages/sg/reviews/the-mysa-mattress.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-the-mysa-mattress-tsx" */),
  "component---src-pages-sg-reviews-the-original-mattress-tsx": () => import("./../../../src/pages/sg/reviews/the-original-mattress.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-the-original-mattress-tsx" */),
  "component---src-pages-sg-reviews-the-terra-mattress-tsx": () => import("./../../../src/pages/sg/reviews/the-terra-mattress.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-the-terra-mattress-tsx" */),
  "component---src-pages-sg-reviews-the-woosa-pillow-tsx": () => import("./../../../src/pages/sg/reviews/the-woosa-pillow.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-the-woosa-pillow-tsx" */),
  "component---src-pages-sg-reviews-the-woosa-storage-base-tsx": () => import("./../../../src/pages/sg/reviews/the-woosa-storage-base.tsx" /* webpackChunkName: "component---src-pages-sg-reviews-the-woosa-storage-base-tsx" */),
  "component---src-pages-sg-select-delivery-tsx": () => import("./../../../src/pages/sg/select-delivery.tsx" /* webpackChunkName: "component---src-pages-sg-select-delivery-tsx" */),
  "component---src-pages-sg-shipping-tsx": () => import("./../../../src/pages/sg/shipping.tsx" /* webpackChunkName: "component---src-pages-sg-shipping-tsx" */),
  "component---src-pages-sg-sleep-trial-tsx": () => import("./../../../src/pages/sg/sleep-trial.tsx" /* webpackChunkName: "component---src-pages-sg-sleep-trial-tsx" */),
  "component---src-pages-sg-storage-the-woosa-storage-base-tsx": () => import("./../../../src/pages/sg/storage/the-woosa-storage-base.tsx" /* webpackChunkName: "component---src-pages-sg-storage-the-woosa-storage-base-tsx" */),
  "component---src-pages-sg-summary-tsx": () => import("./../../../src/pages/sg/summary.tsx" /* webpackChunkName: "component---src-pages-sg-summary-tsx" */),
  "component---src-pages-sg-support-tsx": () => import("./../../../src/pages/sg/support.tsx" /* webpackChunkName: "component---src-pages-sg-support-tsx" */),
  "component---src-pages-sg-warranty-adjustable-tsx": () => import("./../../../src/pages/sg/warranty/adjustable.tsx" /* webpackChunkName: "component---src-pages-sg-warranty-adjustable-tsx" */),
  "component---src-pages-sg-warranty-seres-silk-duvet-tsx": () => import("./../../../src/pages/sg/warranty/seres-silk-duvet.tsx" /* webpackChunkName: "component---src-pages-sg-warranty-seres-silk-duvet-tsx" */),
  "component---src-pages-sg-warranty-storage-base-tsx": () => import("./../../../src/pages/sg/warranty/storage-base.tsx" /* webpackChunkName: "component---src-pages-sg-warranty-storage-base-tsx" */),
  "component---src-pages-sg-warranty-the-woosa-mattress-tsx": () => import("./../../../src/pages/sg/warranty/the-woosa-mattress.tsx" /* webpackChunkName: "component---src-pages-sg-warranty-the-woosa-mattress-tsx" */),
  "component---src-pages-sg-warranty-the-woosa-pillow-tsx": () => import("./../../../src/pages/sg/warranty/the-woosa-pillow.tsx" /* webpackChunkName: "component---src-pages-sg-warranty-the-woosa-pillow-tsx" */),
  "component---src-pages-sg-warranty-waterproof-mattress-protector-tsx": () => import("./../../../src/pages/sg/warranty/waterproof-mattress-protector.tsx" /* webpackChunkName: "component---src-pages-sg-warranty-waterproof-mattress-protector-tsx" */),
  "component---src-pages-shipping-tsx": () => import("./../../../src/pages/shipping.tsx" /* webpackChunkName: "component---src-pages-shipping-tsx" */),
  "component---src-pages-sleep-trial-tsx": () => import("./../../../src/pages/sleep-trial.tsx" /* webpackChunkName: "component---src-pages-sleep-trial-tsx" */),
  "component---src-pages-storage-the-woosa-storage-base-tsx": () => import("./../../../src/pages/storage/the-woosa-storage-base.tsx" /* webpackChunkName: "component---src-pages-storage-the-woosa-storage-base-tsx" */),
  "component---src-pages-summary-tsx": () => import("./../../../src/pages/summary.tsx" /* webpackChunkName: "component---src-pages-summary-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-warranty-adjustable-tsx": () => import("./../../../src/pages/warranty/adjustable.tsx" /* webpackChunkName: "component---src-pages-warranty-adjustable-tsx" */),
  "component---src-pages-warranty-seres-silk-duvet-tsx": () => import("./../../../src/pages/warranty/seres-silk-duvet.tsx" /* webpackChunkName: "component---src-pages-warranty-seres-silk-duvet-tsx" */),
  "component---src-pages-warranty-storage-base-tsx": () => import("./../../../src/pages/warranty/storage-base.tsx" /* webpackChunkName: "component---src-pages-warranty-storage-base-tsx" */),
  "component---src-pages-warranty-the-woosa-mattress-tsx": () => import("./../../../src/pages/warranty/the-woosa-mattress.tsx" /* webpackChunkName: "component---src-pages-warranty-the-woosa-mattress-tsx" */),
  "component---src-pages-warranty-the-woosa-pillow-tsx": () => import("./../../../src/pages/warranty/the-woosa-pillow.tsx" /* webpackChunkName: "component---src-pages-warranty-the-woosa-pillow-tsx" */),
  "component---src-pages-warranty-waterproof-mattress-protector-tsx": () => import("./../../../src/pages/warranty/waterproof-mattress-protector.tsx" /* webpackChunkName: "component---src-pages-warranty-waterproof-mattress-protector-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

