module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"752388431620604"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KPVVPQD","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-773591121","G-0771F6YDDB"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"woosa","short_name":"woosa","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/static/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f56dbc32f2e2b5a152402acee4435522"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://589a22f54a534f67b6efbfab129b718c@o919532.ingest.sentry.io/5864587","sampleRate":1,"environment":"production"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
